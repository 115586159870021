import React from 'react'


function About() {
  return (
    <>
     <div className="container-xxl py-5 bg-grey legends_sec" id="about">
          <div className="container">
            <div className="row text-center mb-5">
              <p className="sub-title">LEGENDS</p>
              <h2 className='text-light'>ABOUT GO.ARCH</h2>
            </div>
            <div className="row align-items-center">
              <div
                className="col-lg-6 col-md-6 mb-4 wow slideInLeft"
                data-wow-duration="1s"
              >
                <h2 className="mb-4 text-light">we turn ideas into works of art.</h2>
                <p className="common-text mb-4">
                  When every little detail is perfected , your abode will be
                  too. sophisticated 2 & 3BHK residences presenting you the best
                  of everything life has to offer.​
                </p>
                <p className="common-text mb-4">
                  The spectacular elevation is nothing less than a captivating
                  vision in lavish glory where it oozes uniqueness and
                  exclusivity.
                </p>
              </div>
              <div
                className="col-lg-6 col-md-6 mb_center wow slideInRight"
                data-wow-duration="1s"
              >
                <img
                  className="img-fluid"
                  src="img/LEGENDS_img.png"
                  width="670"
                  height="400"
                  alt="images"
                />
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default About