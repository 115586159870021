export const projectList = [
  {
    name: "FLOWER VALLEY",
    img: "img/gallary_1.png",
    pdf: "/pdf/Flower-Valley.pdf",
    metaImg: [
      { src: "img/Flower_Valley/1.png" },
      { src: "img/Flower_Valley/2.png" },
      { src: "img/Flower_Valley/3.png" },
      { src: "img/Flower_Valley/4.png" },
      { src: "img/Flower_Valley/5.png" },
      { src: "img/Flower_Valley/6.png" },
      { src: "img/Flower_Valley/7.png" },
      { src: "img/Flower_Valley/8.png" },
      { src: "img/Flower_Valley/9.png" },
      { src: "img/Flower_Valley/10.png" },
      { src: "img/Flower_Valley/11.png" },
      { src: "img/Flower_Valley/12.png" },
      { src: "img/Flower_Valley/13.png" },
    ],
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3718.3408420024703!2d72.88780987472059!3d21.257971679867538!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be0498836147c2f%3A0x522ccb65a737f716!2sFlower%20Valley!5e0!3m2!1sen!2sin!4v1705758285093!5m2!1sen!2sin",
    type: "3 BHK",
  },
  {
    name: "NATURE VALLEY",
    img: "img/gallary_2.png",
    pdf: "/pdf/Nature-Valley.pdf",
    metaImg: [
      { src: "img/Nature_Valley/1.jpg" },
      { src: "img/Nature_Valley/2.jpg" },
      { src: "img/Nature_Valley/3.jpg" },
      { src: "img/Nature_Valley/4.jpg" },
      { src: "img/Nature_Valley/5.jpg" },
      { src: "img/Nature_Valley/6.jpg" },
      { src: "img/Nature_Valley/7.jpg" },
      { src: "img/Nature_Valley/8.jpg" },
      { src: "img/Nature_Valley/9.jpg" },
      { src: "img/Nature_Valley/10.jpg" },
      { src: "img/Nature_Valley/11.jpg" },
      { src: "img/Nature_Valley/12.jpg" },
      { src: "img/Nature_Valley/13.jpg" },
    ],
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3718.3299740908324!2d72.88575438516341!3d21.258402129456766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be0494f06c5b741%3A0xa2bb68a51da0ebd1!2sNATURE%20VALLEY!5e0!3m2!1sen!2sin!4v1710569351435!5m2!1sen!2sin",
    type: "3 BHK",
  },
  {
    name: "LOTUS VALLEY",
    img: "img/gallary_3_l.png",
    pdf: "/pdf/Lotus-Valley.pdf",
    metaImg: [
      { src: "img/Lotus_Valley/1.jpeg" },
      { src: "img/Lotus_Valley/2.jpeg" },
      { src: "img/Lotus_Valley/3.jpeg" },
      { src: "img/Lotus_Valley/4.jpeg" },
      { src: "img/Lotus_Valley/5.jpeg" },
      { src: "img/Lotus_Valley/6.jpeg" },
      { src: "img/Lotus_Valley/7.jpeg" },
      { src: "img/Lotus_Valley/8.jpeg" },
      { src: "img/Lotus_Valley/9.jpeg" },
      { src: "img/Lotus_Valley/10.jpeg" },
      { src: "img/Lotus_Valley/11.jpeg" },
    ],
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17687.644083011743!2d72.87927803225665!3d21.25685138873152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be049002223788b%3A0x9e66a7f1c7267312!2sLotus%20Valley!5e0!3m2!1sen!2sin!4v1710569570391!5m2!1sen!2sin",
    type: "3 BHK",
  },
];
