import React from "react";

function DesignCompany() {
  return (
    <>
      <div className="container-xxl py-5 bg-grey">
        <div className="container">
          <div className="row text-center mb-5">
            <p className="sub-title">WELCOME</p>
            <h2 className="text-light">
              WE ARE CREATIVE BUILDING - DESIGN COMPANY
            </h2>
          </div>
          <div className="row align-items-center">
            <div
              className="col-lg-6 col-md-6 mb-4 mb_center text-center wow slideInLeft"
              data-wow-duration="1s"
            >
              <img
                className="img-fluid"
                src="img/building_img.png"
                width="500"
                height="400"
                alt="images"
              />
            </div>
            <div
              className="col-lg-6 col-md-6 wow slideInRight"
              data-wow-duration="1s"
            >
              <p className="common-text mb-4">
                Something very sleek and sophisticated for class apart space is
                peeping up in your lives for becoming your enernal part as your
                private space.
              </p>
              <p className="common-text mb-4">
                Yes, it's here in your city where you wolud really want to have
                your own settings to live your life. it's yet another latest
                venture of high - end residential space by none other than
                renowned​
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DesignCompany;
