import React from "react";
import Slider from "react-slick";
import Navbar from "./Navbar";

function Banner_slider() {
  const settings = {
    arrows: false,
    dots: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 1000,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    focusOnSelect: true,
  };
  return (
    <div className="container-xxl position-relative p-0">
      <Navbar />
      <div className="bg-black hero-header " id="home">
        <div className="slick-blog position-relative">
          <Slider {...settings}>
            <div className="banner_slider">
              <div className="banner_item">
                <div className="banner_img">
                  <img
                    src="img/Slider_home_1.png"
                    className="img-fluid w-100"
                    alt="images"
                  />
                </div>
                <div className="banner_info w-100 align-center justify-content-center">
                  <h1 className="text-light" style={{ wordBreak: "break-all" }}>
                    ARRIVALS <br /> A <span>CLASS APART</span>
                  </h1>
                  <p className="">2 & 3 BHK LIFESTYLE LIVING</p>
                </div>
              </div>
            </div>

            <div className="banner_slider">
              <div className="banner_item">
                <div className="banner_img">
                  <img
                    src="img/Slider_home_2.png"
                    className="img-fluid w-100"
                    alt="images"
                  />
                </div>
                <div className="banner_info">
                  <h1 className="text-light">
                    BUILD VISION <br /> WITH <span>OUR PASSION</span>
                  </h1>
                  <p className="">LUXURY LIVING WITH ULTRA FACILITY</p>
                </div>
              </div>
            </div>

            <div className="banner_slider">
              <div className="banner_item">
                <div className="banner_img">
                  <img
                    src="img/Slider_home_3.png"
                    className="img-fluid w-100"
                    alt="images"
                  />
                </div>
                <div className="banner_info">
                  <h1 className="text-light">
                    Construction Industrial <span>Building</span>
                  </h1>
                  <p className="">The Best Construction Theme Forever</p>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Banner_slider;
