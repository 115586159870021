import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import "yet-another-react-lightbox/plugins/thumbnails.css";

function GallaryCard({ prject }) {
  const [isOpen, setIsOpen] = useState(false);
  const [images, setImages] = useState(prject?.metaImg || []);
  const handleClick = () => {
    setImages(prject?.metaImg);
    setIsOpen(true);
  };

  const onButtonClick = (path) => {
    fetch(path).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = path;
        alink.click();
      });
    });
  };
  return (
    <>
      <div className="col-lg-4 col-md-4 col-sm-4 mb-3 wow fadeInUp">
        <div className="gallery_blog">
          <img
            onClick={() => handleClick()}
            src={prject?.img}
            alt="Avatar"
            className="image pointer"
          />
          <div className="overlay">{prject?.name}</div>
          {prject?.pdf && (
            <i
              className="fa-solid fa-circle-arrow-down download"
              onClick={() => onButtonClick(prject?.pdf)}
            ></i>
          )}
        </div>
      </div>

      {prject?.metaImg?.length > 0 && <Lightbox
        open={isOpen}
        close={() => setIsOpen(false)}
        slides={images}
        plugins={[Fullscreen, Slideshow, Thumbnails]}
      />}
    </>
  );
}

export default GallaryCard;
