import React from "react";
import { projectList } from "../utils/data";
import GallaryCard from "./GallaryCard";

function Gallary() {
  return (
    <>
      <div className="container-xxl py-5 bg-grey gallary_sec">
        <div className="container">
          <div className="row text-center mb-4">
            <p className="sub-title">Latest Projects</p>
            <h2 className="text-light">Gallery</h2>
          </div>
          <div className="row align-items-center">
            {projectList?.map((prject, i) => {
              return <GallaryCard key={i} prject={prject} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Gallary;
