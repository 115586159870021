import React from "react";

function BuildingProcess() {
  return (
    <>
      <div className="container-xxl py-5 bg-grey building_sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 mb_center wow zoomIn">
              <div className="building_process">
                <img
                  src="img/process_img.png"
                  alt="Snow"
                  className="w-100 img-fluid"
                  height="500"
                />
                <div className="centered">BUILDING PROCESS</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BuildingProcess;
