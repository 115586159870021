import React from "react";

function Footer() {
  return (
    <>
      <div
        className="container-fluid footer pt-5"
        id="contact"
        data-wow-delay="0.1s"
      >
        <div className="container py-3">
          <div className="row">
            <div
              className="col-md-5 col-lg-5 wow slideInLeft"
              data-wow-duration="1s"
            >
              <a href="index.html" className="navbar-brand p-0">
                <img src="img/shiv_logo.png" alt="Logo" />
              </a>
              <p
                style={{ maxWidth: "430px", fontSize: "13px" }}
                className="mt-2"
              >
                Our cilents range from FTSE companiesto large organisations and
                some samll local businesses who are striving to expand.
              </p>
              <p style={{ maxWidth: "430px", fontSize: "13px" }}>
                To see a detailed list of our works and the progress please see
                our project page.
              </p>
            </div>
            <div className="col-md-3 col-lg-4 mb-2 wow fadeInUp">
              <h5 className="mb-4">Navigation</h5>
              <a className="btn btn-link" href="#home">
                Home
              </a>
              <a className="btn btn-link" href="#about">
                About us
              </a>
              <a className="btn btn-link" href="#contact">
                Contact us
              </a>
            </div>
            <div
              className="col-md-4 col-lg-3 wow slideInRight"
              data-wow-duration="1s"
            >
              <h5 className="mb-4">CONTACT US</h5>
              <p>
                <i className="fa fa-location-arrow me-3" aria-hidden="true"></i>
                we are avilable more than 10 hours a day for your service. You
                can contact or visit us in our office from 7:00 - 17:00
              </p>
              <p>
                <i className="fa fa-envelope me-3"></i>
                <a href="mailto:shivdevelopers@gmail.com">
                  shivdevelopers@gmail.com
                </a>
              </p>
              <p>
                <i className="fa fa-phone-alt me-3"></i>
                <a href="tel:+91 9081287876">+91 9081287876</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
