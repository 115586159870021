import React from "react";

function Idyllic() {
  return (
    <>
      <div className="container-xxl py-5 bg-grey idyllic_sec">
        <div className="container">
          <div className="row text-center mb-5">
            <p className="sub-title">Idyllic</p>
            <h2 className="text-light">The Fragrance of life</h2>
          </div>
          <div className="row align-items-center">
            <div
              className="col-lg-6 col-md-6 mb-4 wow slideInLeft"
              data-wow-duration="1s"
            >
              <p className="common-text mb-4">
                Let the ecstasy of expansive views inspire you to do better be
                better. the enchanting skyline of the city.
              </p>
              <p className="common-text mb-4">
                the maganiflcent sunsets, and the captivating sunrises; discover
                the simple joys of existence with Nature Valley grand windows
                and spacious balconies​
              </p>
            </div>
            <div
              className="col-lg-6 col-md-6 mb_center mb-4 wow slideInRight"
              data-wow-duration="1s"
            >
              <img
                className="img-fluid"
                src="img/IDYLLIC_img.png"
                width="670"
                height="400"
                alt="images"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Idyllic;
