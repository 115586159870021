import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "animate.css/animate.min.css";
import { useEffect } from "react";
import "animate.css";
import WOW from "wowjs";
import "animate.css/animate.min.css";
import BannerSlider from "./components/BannerSlider";
import DesignCompany from "./components/DesignCompany";
import Idyllic from "./components/Idyllic";
import BuildingProcess from "./components/BuildingProcess";
import About from "./components/About";
import Gallary from "./components/Gallary";
import Map from "./components/Map";
import Footer from "./components/Footer";

function App() {
  useEffect(() => {
    const newWOW = () => {
      new WOW.WOW({ live: false }).init();
    };
    newWOW();
  }, []);
  return (
    <div class="container-xxl bg-white p-0">
      <BannerSlider />
      <DesignCompany />
      <Idyllic />
      <BuildingProcess />
      <About />
      <Gallary />
      <Map />
      <Footer />
    </div>
  );
}

export default App;
