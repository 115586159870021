import React from "react";
import { projectList } from "../utils/data";
import Slider from "react-slick";

function Map() {
  const settings = {
    arrows: true,
    dots: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 1000,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    focusOnSelect: true,
  };
  return (
    <>
      <div className="container-xxl py-5 bg-grey map_sec">
        <div className="container">
          <Slider {...settings}>
            {projectList.map((project) => {
              return (
                <>
                  <div className="row text-center mb-4">
                    <p className="sub-title">WE WORKED WITH {project?.name}</p>
                    <p className="text-white">
                      <span>{project?.type}</span> LIFESTYLE LIVING
                    </p>
                  </div>
                  <div className="row align-items-center mb-3">
                    <div className="col-lg-12 col-md-12 mb_center wow zoomIn">
                      <div className="map">
                        <iframe
                          src={project?.map}
                          width="600"
                          height="450"
                          style={{ border: 0 }}
                          allowfullscreen=""
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                          title="map"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </Slider>
        </div>
      </div>
    </>
  );
}

export default Map;
