import React, { useEffect } from "react";
import AOS from "aos";

function Navbar() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <nav className="navbar  navbar-expand-lg navbar-light py-lg-0">
        <div className="container align-items-start">
          <a href="index.html" className="navbar-brand">
            <img src="img/shiv_logo.png" alt="Logo" />
          </a>
          <div>
            <div className="navbar-nav ms-auto py-0">
              <a href="#home" className="nav-item nav-link">
                Home
              </a>
              <a href="#about" className="nav-item nav-link">
                About US
              </a>
              <a href="#contact" className="nav-item nav-link">
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
